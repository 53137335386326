import { Flex, Text } from '@chakra-ui/react';
import React from "react";
import ConnectButton from '../ConnectButton/ConnectButton';
import StupidBird from '../StupidBird/StupidBird';
import "./HomePage.css";

const HomePage = ({publicKey, setPublicKey}) => {

    return (
      <Flex className="home-page">
      <Text className='text-flip'>WELCOME STUPIDOS!</Text>
      {publicKey?
      <StupidBird publicKey={publicKey} setPublicKey={setPublicKey} />:
      <ConnectButton publicKey={publicKey} setPublicKey={setPublicKey} ClassName='bird-button' ButtonText={"Stupid Bird"}/>
      }
      </Flex>
    );
  }

export default HomePage;

