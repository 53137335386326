import { Box, Flex, Image, Link, Spacer } from '@chakra-ui/react';
import { Connection } from '@solana/web3.js';
import React, { useEffect, useState } from 'react';
import Discord from '../../assets/social-media-icons/discord.png';
import Twitter from '../../assets/social-media-icons/twitter.png';
import ConnectButton from '../ConnectButton/ConnectButton';
import './NavBar.css';

const NavBar = ({ publicKey, setPublicKey }) => {
  const [connection, setConnection] = useState(null);
  useEffect(() => {
    // Initialize Solana Web3 connection to the mainnet
    const solanaConnection = new Connection(
      'https://api.mainnet-beta.solana.com'
    );
    setConnection(solanaConnection);
  }, []);

  const connectWallet = async () => {
    if (window.solana) {
      try {
        await window.solana.connect();
        const connectedPublicKey = window.solana.publicKey;
        if (connectedPublicKey.toString() !== publicKey) {
          setPublicKey(connectedPublicKey.toString());
        }
      } catch (err) {
        console.error('Error connecting to wallet:', err);
      }
    } else {
      console.error('Phantom wallet not found. Please install it.');
    }
  };

  const shortKey = (text) => {
    const firstFour = text.slice(0, 4);
    const lastFour = text.slice(-4);

    return `${firstFour}...${lastFour}`;
  };

  const disconnectWallet = () => {
    if (window.solana) {
      window.solana.disconnect();
      setPublicKey(null);
    }
  };

  return (
    <Flex justify='space-between' align='center' paddingTop='1rem'>
      {/* LEFT SIDE*/}
      <Flex className='left-side'>
        <Link href='https://twitter.com/StupidosNFT'>
          <Image
            className='twitter'
            src={Twitter}
            boxSize='3rem'
            margin='0 1rem'
          ></Image>
        </Link>
        <Link href='https://discord.com/invite/stupidosnft'>
          <Image
            className='discord'
            src={Discord}
            boxSize='3rem'
            margin='0 1rem'
          ></Image>
        </Link>
        {/* <Link href='/'>
          <Image src={MagicEden} boxSize='42px' margin='0 15px'></Image>
        </Link> */}
      </Flex>

      {/* RIGHT SIDE */}
      <Flex className='right-side'>
        <Spacer />
        {publicKey ? (
          <>
            {shortKey(publicKey)}&nbsp;
            <Box
              cursor='pointer'
              textDecoration='underline white'
              onClick={disconnectWallet}
              margin='0'
            >
              Disconnect
            </Box>
          </>
        ) : (
          <ConnectButton publicKey={publicKey} setPublicKey={setPublicKey} ClassName="navbar-button" ButtonText={"Connect"} />
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
