import { useState } from 'react';

import React, { useEffect } from 'react';
import "./StupidBird.css";
import { Flex } from '@chakra-ui/react';

const StupidBird = ({ publicKey, setPublicKey }) => {
  const [paid, setPaid] = useState(false);
  const [top, setTop] = useState([]);
  const pay = () => {
    setPaid(true);
  }

  useEffect(() => {
    fetch(`https://stupidos-1b58883c7fe6.herokuapp.com/app/top`, { 
    }) 
      .then((response) => { 
        if (!response.ok) { 
          throw new Error('Network response was not ok'); 
        } 
        return response.json(); 
      }) 
      .then((result) => { 
        if (result) { 
          setTop(result); 
          console.log(result); 
        } else { 
          alert('You are not holder'); 
        } 
      }) 
      .catch((err) => { 
        alert(err); 
      });
    if (!publicKey) return;
    window.key = publicKey;
    // This code will run after the component has rendered
    const script = document.createElement('script');
    script.src = '../flappybird.js'; // Replace with the path to your JavaScript file
    script.async = true;
    script.onload = () => {
      function run() {

        //board
        let board;
        let boardWidth = 360;
        let boardHeight = 640;
        let context;

        //bird
        let birdWidth = 34; //width/height ratio = 408/228 = 17/12
        let birdHeight = 24;
        let birdX = boardWidth   / 8;
        let birdY = boardHeight / 2;
        let birdImg;

        let bird = {
          x: birdX,
          y: birdY,
          width: birdWidth,
          height: birdHeight,
        };

        //pipes
        let pipeArray = [];
        let pipeWidth = 64; //width/height ratio = 384/3072 = 1/8
        let pipeHeight = 512;
        let pipeX = boardWidth;
        let pipeY = 0;

        let topPipeImg;
        let bottomPipeImg;

        //physics
        let velocityX = -1.5; //pipes moving left speed
        let velocityY = 0; //bird jump speed
        let gravity = 0.15;

        let gameOver = false;
        let score = 0;

        board = document.getElementById('board');
        board.height = boardHeight;
        board.width = boardWidth;
        context = board.getContext('2d'); //used for drawing on the board

        //draw flappy bird
        // context.fillStyle = "green";
        // context.fillRect(bird.x, bird.y, bird.width, bird.height);

        //load images
        birdImg = new Image();
        birdImg.src = './bird.png';
        birdImg.onload = function () {
          context.drawImage(birdImg, bird.x, bird.y, bird.width, bird.height);
        };

        topPipeImg = new Image();
        topPipeImg.src = './column1.png';

        bottomPipeImg = new Image();
        bottomPipeImg.src = './colum2.png';

        requestAnimationFrame(update);
        setInterval(placePipes, 1500); //every 1.5 seconds
        document.addEventListener('keydown', moveBird);
        document.addEventListener('click', moveBird);

        function update() {
          requestAnimationFrame(update);
          if (gameOver) {
            return;
          }
          context.clearRect(0, 0, board.width, board.height);

          //bird
          velocityY += gravity;
          // bird.y += velocityY;
          bird.y = Math.max(bird.y + velocityY, 0); //apply gravity to current bird.y, limit the bird.y to top of the canvas
          context.drawImage(birdImg, bird.x, bird.y, bird.width, bird.height);

          if (bird.y > board.height) {
            console.log('1', score)
            if (score > 0) {
              fetch('https://stupidos-1b58883c7fe6.herokuapp.com/app', { 
                method: 'POST', 
                headers: { 
                  'Content-Type': 'application/json', 
                }, 
                body: JSON.stringify({ 
                  score, 
                  address: publicKey 
                }), 
              }).then((data) => { 
                console.log('Response:', data); 
                // setPaid(false) 
 
              }) 
                .catch((error) => { 
                  console.error('Error:', error); 
                  // setPaid(false) 
                });
            }

            gameOver = true;
          }

          //pipes
          for (let i = 0; i < pipeArray.length; i++) {
            let pipe = pipeArray[i];
            pipe.x += velocityX;
            context.drawImage(pipe.img, pipe.x, pipe.y, pipe.width, pipe.height);

            if (!pipe.passed && bird.x > pipe.x + pipe.width) {
              score += 0.5; // 0.5 because there are 2 pipes! so 0.5*2 = 1, 1 for each set of pipes
              pipe.passed = true;
            }

            if (detectCollision(bird, pipe)) {
              if(score > 0) {
              fetch('https://stupidos-1b58883c7fe6.herokuapp.com/app', { 
                method: 'POST', 
                headers: { 
                  'Content-Type': 'application/json', 
                }, 
                body: JSON.stringify({ 
                  score, 
                  address: publicKey 
                }), 
              }).then((data) => { 
                console.log('Response:', data); 
                // setPaid(false) 
 
              }) 
                .catch((error) => { 
                  console.error('Error:', error); 
                  // setPaid(false) 
                });
              }
              gameOver = true;
            }
          }

          //clear pipes
          while (pipeArray.length > 0 && pipeArray[0].x < -pipeWidth) {
            pipeArray.shift(); //removes first element from the array
          }

          //score
          context.fillStyle = 'white';
          context.font = '1.5rem sans-serif';
          context.fillText(score, 5, 45);

          if (gameOver) {
            context.fillText('GAME OVER', 5, 90);
          }
        }

        function placePipes() {
          if (gameOver) {
            return;
          }

          //(0-1) * pipeHeight/2.
          // 0 -> -128 (pipeHeight/4)
          // 1 -> -128 - 256 (pipeHeight/4 - pipeHeight/2) = -3/4 pipeHeight
          let randomPipeY =
            pipeY - pipeHeight / 4 - Math.random() * (pipeHeight / 2);
          let openingSpace = board.height / 4;

          let topPipe = {
            img: topPipeImg,
            x: pipeX,
            y: randomPipeY,
            width: pipeWidth,
            height: pipeHeight,
            passed: false,
          };
          pipeArray.push(topPipe);

          let bottomPipe = {
            img: bottomPipeImg,
            x: pipeX,
            y: randomPipeY + pipeHeight + openingSpace,
            width: pipeWidth,
            height: pipeHeight,
            passed: false,
          };
          pipeArray.push(bottomPipe);
        }

        function moveBird(e) {
          if (e.code == 'Space' || e.code == 'ArrowUp' || e.code == 'KeyX' || e?.type == 'click') {
            //jump
            velocityY = -6;

            //reset game
            if (gameOver) {
              bird.y = birdY;
              pipeArray = [];
              score = 0;
              gameOver = false;
            }
          }
        }

        function detectCollision(a, b) {
          return (
            a.x < b.x + b.width && //a's top left corner doesn't reach b's top right corner
            a.x + a.width > b.x && //a's top right corner passes b's top left corner
            a.y < b.y + b.height && //a's top left corner doesn't reach b's bottom left corner
            a.y + a.height > b.y
          ); //a's bottom left corner passes b's top left corner
        }
      }


      console.log(paid)
      if (paid) {
        fetch(`https://stupidos-1b58883c7fe6.herokuapp.com/app/${publicKey}`, {
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((result) => {
            // Check if the key exists in nftHolding
            if (result.result) {
              run();

            } else {
              alert('You are not holder');
            }
          })
          .catch((err) => {
            alert(err);
          });
      }

    };
    document.body.appendChild(script);

    return () => {
      // Clean up if needed (e.g., remove the script element)
      document.body.removeChild(script);
    };
  }, [publicKey, paid]); // Empty dependency array ensures this effect runs only once

  return (
    <Flex className="main-flex"> 
      {!paid ?
        <>
          <button className='paybutton' onClick={() => pay()}>Play</button>
        </> :
        <Flex className='bird-flex'>
          <canvas  id='board' />
        </Flex>
      }
    </Flex>
  );
};

export default StupidBird;
