import { Button, Flex } from '@chakra-ui/react';
import { Connection } from '@solana/web3.js';
import React, { useEffect, useState } from 'react';

const ConnectButton = ({ publicKey, setPublicKey, ClassName, ButtonText }) => {

  const [connection, setConnection] = useState(null);
  useEffect(() => {
    // Initialize Solana Web3 connection to the mainnet
    const solanaConnection = new Connection(
      'https://api.mainnet-beta.solana.com'
    );
    setConnection(solanaConnection);
  }, []);

  const connectWallet = async () => {
    if (window.solana) {
      try {
        await window.solana.connect();
        const connectedPublicKey = window.solana.publicKey;
        if (connectedPublicKey.toString() !== publicKey) {
          setPublicKey(connectedPublicKey.toString());
        }
      } catch (err) {
        console.error('Error connecting to wallet:', err);
      }
    } else {
      console.error('Phantom wallet not found. Please install it.');
    }
  };

  const shortKey = (text) => {
    const firstFour = text.slice(0, 4);
    const lastFour = text.slice(-4);

    return `${firstFour}...${lastFour}`;
  };

  const disconnectWallet = () => {
    if (window.solana) {
      window.solana.disconnect();
      setPublicKey(null);
    }
  };

  return (
    <Flex justify='space-between' align='center' paddingTop='1rem'>
      {publicKey ?
        <Button className={ClassName} onClick={disconnectWallet}>
          Disconnect
        </Button> :
        <Button className={ClassName} onClick={connectWallet}>
          {ButtonText}
        </Button>
      }
    </Flex>
  );
};

export default ConnectButton;
