import { useState } from 'react';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import HomePage from './components/HomePage/HomePage';

function App() {
  const [publicKey, setPublicKey] = useState(null);
  return (
    <div className='overlay'>
      <div className='App'>
        <NavBar publicKey={publicKey} setPublicKey={setPublicKey} />
        {/* <CoinFlip publicKey={publicKey} setPublicKey={setPublicKey}  /> */}
         <HomePage publicKey={publicKey} setPublicKey={setPublicKey} /> 

      </div>
      <div className='background'></div>
    </div>
  );
}

export default App;
